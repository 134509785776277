
import { defineComponent, nextTick } from 'vue';
import TransitionSlide from '@/components/TransitionSlide.vue';

export default defineComponent({
  components: {
    TransitionSlide,
  },
  emits: [
    'transferComplete',
  ],
  data: () => ({
    formShowing: false,
    formFields: {
      amount: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'business-portal/transfer_from_ewallet/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 204) {
        this.$emit('transferComplete', responseData.body);
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
