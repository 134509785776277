<template>
  <div class="component">
    <transition-slide mode="out-in">
      <div
        v-if="formShowing === false"
        class="text-center"
      >
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          @click="formShowing = true"
        >
          Transfer Funds
        </button>
      </div>

      <form
        v-else
        @submit.prevent="submitForm"
      >
        <p class="text-center">
          Please note that PayPal has a 2% transfer fee (capped at $1).
        </p>

        <alert
          v-if="formErrors.non_field_errors !== undefined"
          variant="danger"
          class="js-form-error"
          dismissible
          @dismissed="formErrors = {}"
        >
          {{ formErrors.non_field_errors[0] }}
        </alert>

        <div class="form-group">
          <label for="amount">Amount to transfer</label>
          <div class="input-with-prefix">
            <span class="input-prefix">$</span>
            <input
              id="amount"
              v-model="formFields.amount"
              :class="{ 'is-invalid': formErrors.amount !== undefined }"
              type="text"
              inputmode="decimal"
              required
            >
          </div>
          <div
            v-if="formErrors.amount !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.amount[0] }}
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary"
          :disabled="formSubmitting"
        >
          <template v-if="formSubmitting">
            Transferring
            <spinner />
          </template>

          <template v-else>
            Transfer
          </template>
        </button>
      </form>
    </transition-slide>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';
import TransitionSlide from '@/components/TransitionSlide.vue';

export default defineComponent({
  components: {
    TransitionSlide,
  },
  emits: [
    'transferComplete',
  ],
  data: () => ({
    formShowing: false,
    formFields: {
      amount: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'business-portal/transfer_from_ewallet/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 204) {
        this.$emit('transferComplete', responseData.body);
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    margin-bottom: 1.5rem;
  }

  form button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .form-group {
    margin: 0 auto;
    width: 16.5em;
  }
</style>
