
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    count: {
      type: Number,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    previous: {
      type: String,
      required: true,
    },
    next: {
      type: String,
      required: true,
    },
    changingPage: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'changePage',
  ],
  data() {
    let url = '';

    if (this.previous) {
      url = this.previous;
    } else if (this.next) {
      url = this.next;
    }

    if (url) {
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.search);
      params.delete('page');
      params.append('page', '');
      url = `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}?${params.toString()}`;
    }

    return {
      pageItems: [] as Array<{
        url: string;
        text: number;
        disabled: false;
      } | {
        text: '…';
        disabled: true;
      }>,
      url,
      showSpinner: false,
      showSpinnerTimeoutID: 0,
    };
  },
  computed: {
    totalPages(): number {
      return Math.ceil(this.count / this.perPage);
    },
  },
  watch: {
    changingPage() {
      if (this.changingPage) {
        this.showSpinnerTimeoutID = setTimeout(() => {
          this.showSpinner = true;
        }, 100);
      } else {
        clearTimeout(this.showSpinnerTimeoutID);
        this.showSpinner = false;
      }
    },
  },
  created() {
    const pageItems = [];

    if (this.totalPages > 1) {
      if (this.totalPages <= 7) {
        // Since there are not that many pages, just show all of them

        for (let i = 1; i <= this.totalPages; i += 1) {
          pageItems.push(this.getPageItem(i));
        }
      } else if (this.pageNumber <= 4) {
        // Show the first five pages, a separator, and the last page

        for (let i = 1; i <= 5; i += 1) {
          pageItems.push(this.getPageItem(i));
        }
        pageItems.push(this.getPageItem('separator'));
        pageItems.push(this.getPageItem(this.totalPages));
      } else if (this.pageNumber >= this.totalPages - 3) {
        // Show the first page, a separator, and the last five pages

        pageItems.push(this.getPageItem(1));
        pageItems.push(this.getPageItem('separator'));
        for (let i = this.totalPages - 4; i <= this.totalPages; i += 1) {
          pageItems.push(this.getPageItem(i));
        }
      } else {
        // Show the first page, a separator, the previous page, the current
        // page, the next page, a separator, and the last page

        pageItems.push(this.getPageItem(1));
        pageItems.push(this.getPageItem('separator'));
        pageItems.push(this.getPageItem(this.pageNumber - 1));
        pageItems.push(this.getPageItem(this.pageNumber));
        pageItems.push(this.getPageItem(this.pageNumber + 1));
        pageItems.push(this.getPageItem('separator'));
        pageItems.push(this.getPageItem(this.totalPages));
      }

      this.pageItems = pageItems;
    }
  },
  methods: {
    getPageItem(item: number | 'separator'): {
      url: string;
      text: number;
      disabled: false;
    } | {
      text: '…';
      disabled: true;
    } {
      if (typeof item === 'number') {
        return {
          url: this.url + item,
          text: item,
          disabled: false,
        };
      }

      return {
        text: '…',
        disabled: true,
      };
    },
  },
});
