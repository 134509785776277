<template>
  <nav
    v-if="totalPages > 1"
    aria-label="Page navigation"
  >
    <ul class="pagination">
      <li
        :class="{disabled: !previous}"
        class="page-item"
      >
        <button
          class="btn-transparent page-button"
          aria-label="Previous"
          @click="$emit('changePage', previous)"
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
      <li
        v-for="(pageItem, index) of pageItems"
        :key="`pageItem${index}`"
        :class="{active: pageItem.text == pageNumber, disabled: pageItem.disabled}"
        class="page-item"
      >
        <button
          v-if="!pageItem.disabled"
          class="btn-transparent page-button"
          @click="$emit('changePage', pageItem.url)"
        >
          {{ pageItem.text }}
        </button>

        <button
          v-else
          class="btn-transparent page-button"
        >
          {{ pageItem.text }}
        </button>
      </li>
      <li
        :class="{disabled: !next}"
        class="page-item"
      >
        <button
          class="btn-transparent page-button"
          aria-label="Next"
          @click="$emit('changePage', next)"
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>

    <div
      v-if="showSpinner"
      class="spinner-container"
    >
      <spinner />
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    count: {
      type: Number,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    previous: {
      type: String,
      required: true,
    },
    next: {
      type: String,
      required: true,
    },
    changingPage: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'changePage',
  ],
  data() {
    let url = '';

    if (this.previous) {
      url = this.previous;
    } else if (this.next) {
      url = this.next;
    }

    if (url) {
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.search);
      params.delete('page');
      params.append('page', '');
      url = `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}?${params.toString()}`;
    }

    return {
      pageItems: [] as Array<{
        url: string;
        text: number;
        disabled: false;
      } | {
        text: '…';
        disabled: true;
      }>,
      url,
      showSpinner: false,
      showSpinnerTimeoutID: 0,
    };
  },
  computed: {
    totalPages(): number {
      return Math.ceil(this.count / this.perPage);
    },
  },
  watch: {
    changingPage() {
      if (this.changingPage) {
        this.showSpinnerTimeoutID = setTimeout(() => {
          this.showSpinner = true;
        }, 100);
      } else {
        clearTimeout(this.showSpinnerTimeoutID);
        this.showSpinner = false;
      }
    },
  },
  created() {
    const pageItems = [];

    if (this.totalPages > 1) {
      if (this.totalPages <= 7) {
        // Since there are not that many pages, just show all of them

        for (let i = 1; i <= this.totalPages; i += 1) {
          pageItems.push(this.getPageItem(i));
        }
      } else if (this.pageNumber <= 4) {
        // Show the first five pages, a separator, and the last page

        for (let i = 1; i <= 5; i += 1) {
          pageItems.push(this.getPageItem(i));
        }
        pageItems.push(this.getPageItem('separator'));
        pageItems.push(this.getPageItem(this.totalPages));
      } else if (this.pageNumber >= this.totalPages - 3) {
        // Show the first page, a separator, and the last five pages

        pageItems.push(this.getPageItem(1));
        pageItems.push(this.getPageItem('separator'));
        for (let i = this.totalPages - 4; i <= this.totalPages; i += 1) {
          pageItems.push(this.getPageItem(i));
        }
      } else {
        // Show the first page, a separator, the previous page, the current
        // page, the next page, a separator, and the last page

        pageItems.push(this.getPageItem(1));
        pageItems.push(this.getPageItem('separator'));
        pageItems.push(this.getPageItem(this.pageNumber - 1));
        pageItems.push(this.getPageItem(this.pageNumber));
        pageItems.push(this.getPageItem(this.pageNumber + 1));
        pageItems.push(this.getPageItem('separator'));
        pageItems.push(this.getPageItem(this.totalPages));
      }

      this.pageItems = pageItems;
    }
  },
  methods: {
    getPageItem(item: number | 'separator'): {
      url: string;
      text: number;
      disabled: false;
    } | {
      text: '…';
      disabled: true;
    } {
      if (typeof item === 'number') {
        return {
          url: this.url + item,
          text: item,
          disabled: false,
        };
      }

      return {
        text: '…',
        disabled: true,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
  $pagination-border-width: 1px;

  nav {
    position: relative;
    display: inline-block;
  }

  .pagination {
    display: flex;
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  .page-button {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -$pagination-border-width;
    line-height: 1.25;
    color: #fff;
    background-color: var(--gray-dark);
    border: $pagination-border-width solid var(--gray);

    &:hover {
      background-color: var(--blue);
    }
  }

  .page-item {
    &:first-child {
      .page-button {
        margin-left: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &:last-child {
      .page-button {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }

    &.active .page-button {
      background-color: var(--blue);
    }

    &.disabled .page-button {
      color: var(--gray-light);
      background-color: var(--gray-darker);
      pointer-events: none;
    }
  }

  .spinner-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    justify-items: center;
    align-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.25rem;
  }
</style>
