<template>
  <div class="horizontal-padding">
    <h1>eWallet</h1>

    <div class="content">
      <ewallet-transactions />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import EwalletTransactions from '@/components/business_portal/EwalletTransactions.vue';

export default defineComponent({
  components: {
    EwalletTransactions,
  },
});
</script>

<style lang="scss" scoped>
  .content {
    margin: 0 auto;
    max-width: 500px;
  }
</style>
