
import { defineComponent } from 'vue';
import Pagination from '@/components/Pagination.vue';
import TransferFromEwallet from './TransferFromEwallet.vue';

export default defineComponent({
  components: {
    Pagination,
    TransferFromEwallet,
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    balance: 0,
    transactions: {} as {
      // eslint-disable-next-line camelcase
      page_number: number;
      // eslint-disable-next-line camelcase
      per_page: number;
      count: number;
      next: string | null;
      previous: string | null;
      results: Array<{
        amount: string;
        balance: string;
        created: string;
        description: string;
      }>;
    },
    changingPage: false,
    transferComplete: false,
  }),
  created() {
    this.loadEwalletData();
  },
  methods: {
    async loadEwalletData() {
      this.status = 'loading';

      const responseData = await this.api({
        url: 'business-portal/ewallet_transactions/',
      });

      if (responseData.status === 200) {
        this.balance = parseFloat(responseData.body.balance);
        this.transactions = responseData.body.transactions;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
    async transactionsPageNav(url: string) {
      if (this.changingPage) {
        return;
      }

      if (url.indexOf('?page') === -1) {
        // eslint-disable-next-line no-param-reassign
        url += '?page=1';
      }

      this.changingPage = true;
      const responseData = await this.api({ url });
      this.changingPage = false;

      if (responseData.status === 200) {
        this.transactions = responseData.body;
      } else {
        this.$swal(
          'Error Changing Page',
          'Please check your connection and try again.',
        );
      }
    },
  },
});
