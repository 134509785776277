<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading your eWallet. Please check your connection
      and try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadEwalletData"
    >
      Try Again
    </button>
  </template>

  <template v-else-if="status === 'loaded'">
    <template v-if="transferComplete">
      <alert variant="success">
        Your transfer has been initiated!
      </alert>

      <button
        type="button"
        class="btn btn-primary"
        @click="loadEwalletData(); transferComplete = false"
      >
        Back to Transactions
      </button>
    </template>

    <template v-else>
      <h2>Balance: ${{ formatCurrency(balance) }}</h2>

      <transfer-from-ewallet
        v-if="balance >= 0.5"
        @transferComplete="transferComplete = true"
      />

      <alert v-else>
        You will be able to transfer money from your eWallet once your balance
        is at least 50¢.
      </alert>

      <template v-if="transactions.count">
        <table>
          <caption>Transactions</caption>
          <thead>
            <tr>
              <th>Date / Time</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, index) of transactions.results"
              :key="`transaction${index}`"
            >
              <td>{{ timestampDisplay(transaction.created) }}</td>
              <td>{{ transaction.description }}</td>
              <td
                class="amount"
                :class="{ deposit: transaction.description.startsWith('Deposit') }"
              >
                <template v-if="!transaction.description.startsWith('Deposit')">-</template>${{ formatCurrency(transaction.amount) }} <!-- eslint-disable-line vue/singleline-html-element-content-newline, max-len -->
              </td>
              <td>${{ formatCurrency(transaction.balance) }}</td>
            </tr>
          </tbody>
        </table>

        <pagination
          :key="`transactions${transactions.page_number}`"
          :count="transactions.count"
          :page-number="transactions.page_number"
          :per-page="transactions.per_page"
          :previous="transactions.previous || ''"
          :next="transactions.next || ''"
          :changing-page="changingPage"
          @changePage="transactionsPageNav"
        />
      </template>
    </template>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Pagination from '@/components/Pagination.vue';
import TransferFromEwallet from './TransferFromEwallet.vue';

export default defineComponent({
  components: {
    Pagination,
    TransferFromEwallet,
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    balance: 0,
    transactions: {} as {
      // eslint-disable-next-line camelcase
      page_number: number;
      // eslint-disable-next-line camelcase
      per_page: number;
      count: number;
      next: string | null;
      previous: string | null;
      results: Array<{
        amount: string;
        balance: string;
        created: string;
        description: string;
      }>;
    },
    changingPage: false,
    transferComplete: false,
  }),
  created() {
    this.loadEwalletData();
  },
  methods: {
    async loadEwalletData() {
      this.status = 'loading';

      const responseData = await this.api({
        url: 'business-portal/ewallet_transactions/',
      });

      if (responseData.status === 200) {
        this.balance = parseFloat(responseData.body.balance);
        this.transactions = responseData.body.transactions;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
    async transactionsPageNav(url: string) {
      if (this.changingPage) {
        return;
      }

      if (url.indexOf('?page') === -1) {
        // eslint-disable-next-line no-param-reassign
        url += '?page=1';
      }

      this.changingPage = true;
      const responseData = await this.api({ url });
      this.changingPage = false;

      if (responseData.status === 200) {
        this.transactions = responseData.body;
      } else {
        this.$swal(
          'Error Changing Page',
          'Please check your connection and try again.',
        );
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  table {
    margin: 0 auto;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    + nav {
      margin-top: 1rem;
    }
  }

  tbody tr:nth-child(odd) {
    background-color: var(--gray-darker);
  }

  th,
  td {
    padding: 0.25rem;
  }

  td {
    vertical-align: top;
    word-wrap: break-word;
  }

  .amount {
    text-align: right;
  }

  .deposit {
    color: var(--green);
  }

  @media (max-width: 639.98px) {
    span {
      display: block;
    }
  }

  @media (min-width: 640px) {
    span {
      margin-right: 0.5em;
    }
  }
</style>
